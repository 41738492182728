import React from "react";

import genderPatner from "../../../utils/functions/gender-partner";

export default function JobRatingPartner(props) {
	return (
		<div className="Job_Rating_Partner_Section">
			<div className="Partner_Section_Top">
				<div className="Partner_Section_Img">
					{props.partner && <img src={props.partner.photo} alt="" className="img-responsive" />}
				</div>
				<div className="text-center Partner_Section_Name">
					<h3 className="Job-rating-partner-name">
						{props.partner.title ? props.partner.title : genderPatner(props.partner)} {props.partner.givenName} {props.partner.lastName}
					</h3>
					<span>{props.partner.coPartner.displayValue || props.partner.coPartner.name} Company</span>
				</div>
			</div>
			<div className="Partner_Section_Text">
				<h5>Help our partners serve you better by rating their job</h5>
			</div>
		</div>
	);
}