import React, { Fragment } from "react";
import _ from 'lodash';

import  SingleLineSkeleton from "../../../ui-elements/Skeleton/SingleLineSkeleton";

export default function JobReceiptCost(props) {

    return (
		<Fragment>
			<div className="job-receipt-cost">
				<p className="total-amount">Total Paid</p>
				{props.loading ? (
					<SingleLineSkeleton />
				) : (
					!_.isEmpty(props.job) &&
					!_.isEmpty(props.client) && (
						<p className="total-amount">
							{props.client.currency.signText}
							{!_.isNull(props.job?.jobCost?.clientTransferMykoin) && props.job?.jobCost?.clientTransferMykoin > 0
								? (props.job.jobCost.value + props.job?.jobCost?.clientTransferMykoin + (props.job.jobCost.transferFee || 0)) % 1 !== 0
									? Number(props.job.jobCost.value + props.job?.jobCost?.clientTransferMykoin + (props.job.jobCost.transferFee || 0)).toFixed(2)
									: props.job.jobCost.value + props.job?.jobCost?.clientTransferMykoin + (props.job.jobCost.transferFee || 0)
								: props.job.jobCost.value + (props.job.jobCost.transferFee || 0)}
						</p>
					)
				)}
			</div>
			<div className="job-receipt-cost">
				<p className="cost-text">Base Service Fee</p>
				{props.loading ? (
					<SingleLineSkeleton />
				) : (
					!_.isEmpty(props.job) &&
					!_.isEmpty(props.client) && (
						<p className="cost">
							{props.client.currency.signText}{" "}
							{props.job.jobCost.serviceFee % 1 !== 0 ? Number(props.job.jobCost.serviceFee).toFixed(2) : props.job.jobCost.serviceFee}
						</p>
					)
				)}
			</div>
			{props.job?.receivedCashAmount && (<div className="job-receipt-cost">
				<p className="cost-text">mykoins Received</p>
				{props.loading ? (
					<SingleLineSkeleton />
				) : (
					!_.isEmpty(props.job) &&
					!_.isEmpty(props.client) && (
						<p className="cost">
							{props.client.currency.signText}{" "}
							{props.job?.receivedCashAmount}
						</p>
					)
				)}
			</div>)}
			{!_.isEmpty(props.job) && !_.isEmpty(props.client) ? (
				!_.isNull(props.job.voucher) ? (
					props.job.voucher.type === "AMOUNT" ? (
						<div className="job-receipt-cost">
							<div className="cost-text">
								{props.job.jobCost.currency} {props.job.voucher.amountOff} discount ({props.job.voucher.code})
							</div>
							<div className="cost">
								- {props.client.currency.signText}{" "}
								{props.job.voucher.totalDiscount % 1 !== 0
									? Number(props.job.voucher.totalDiscount).toFixed(2)
									: props.job.voucher.totalDiscount}
							</div>
						</div>
					) : (
						<div className="job-receipt-cost">
							<div className="cost-text">
								{props.job.voucher.percentOff}% discount ({props.job.voucher.code})
							</div>
							<div className="cost">
								- {props.client.currency.signText}{" "}
								{props.job.voucher.totalDiscount % 1 !== 0
									? Number(props.job.voucher.totalDiscount).toFixed(2)
									: props.job.voucher.totalDiscount}
							</div>
						</div>
					)
				) : null
			) : null}
			{!_.isEmpty(props.job) &&
				!_.isEmpty(props.client) &&
				!_.isNull(props.job.jobCost.extendFee) &&
				props.job.jobCost.extendFee > 0 && (
				<div className="job-receipt-cost">
					<p className="cost-text">Time Extension</p>
					<p className="cost">
						{props.client.currency.signText}{" "}
						{props.job.jobCost.extendFee % 1 !== 0 ? Number(props.job.jobCost.extendFee).toFixed(2) : props.job.jobCost.extendFee}
					</p>
				</div>
			)}

			{!_.isEmpty(props.job) &&
			!_.isEmpty(props.client) &&
			!_.isNull(props.job?.jobCost?.clientTransferMykoin) &&
			props.job?.jobCost?.clientTransferMykoin > 0 &&
			!_.isEmpty(props.client) ? (
				<div className="job-receipt-cost">
					<p className="cost-text">Paid to Partner</p>
					<p className="cost">
						{props.client.currency.signText}{" "}
						{(props.job?.jobCost?.clientTransferMykoin + (props.job.jobCost.transferFee || 0)) % 1 !== 0
							? Number(props.job?.jobCost?.clientTransferMykoin + (props.job.jobCost.transferFee || 0)).toFixed(2)
							: props.job?.jobCost?.clientTransferMykoin + (props.job.jobCost.transferFee || 0)}
					</p>
				</div>
			) : null}

			{!_.isEmpty(props.job) &&
			props.job.jobCost.partnerTransferMykoin &&
			props.job.jobCost.partnerTransferMykoin !== 0 &&
			!_.isEmpty(props.client) ? (
				<div className="job-receipt-cost">
					<p className="cost-text">mykoins Received</p>
					<p className="cost">
						+ {props.client.currency.signText}{" "}
						{props.job.jobCost.partnerTransferMykoin % 1 !== 0
							? Number(props.job.jobCost.partnerTransferMykoin).toFixed(2)
							: props.job.jobCost.partnerTransferMykoin}
					</p>
				</div>
			) : null}
		</Fragment>
	);
}